<script>
  import {onMount} from "svelte";
  import auth from "./auth";
  import {campaigns, user} from "./store";
  import Bootstrap from "./Bootstrap.svelte";
  import Root from "./Root.svelte";

  let ready = false;
  onMount(async () => {
    await auth.checkSession();

    const [firstCharge, secondCharge, specialist] = await Promise.all([
      fetch("/api/first/campaigns", {
        headers: {
          Authorization: `Bearer ${$user.token}`
        }
      }).then(res => res.json()),
      fetch("/api/second/campaigns", {
        headers: {
          Authorization: `Bearer ${$user.token}`
        }
      }).then(res => res.json()),
      fetch("/api/specialist/campaigns", {
        headers: {
          Authorization: `Bearer ${$user.token}`
        }
      }).then(res => res.json())
    ]);

    campaigns.set({ firstCharge, secondCharge, specialist });
    ready = true;
  });
</script>

{#if ready}
    <Root/>
{:else}
    <Bootstrap/>
{/if}
