<script>
  import {parameters, user} from "./store";
  import {ClickableTile, Loading} from "carbon-components-svelte";
  import Detail from "./components/Detail.svelte";

  let currentParameters = null;
  let data = [];
  let loading = false;
  let detailPopup;

  const productMap = {firstCharge: "first", secondCharge: "second", specialist: "specialist"};

  async function update() {
    loading = true;
    let url = `/api/${productMap[currentParameters.product]}/summary/${currentParameters.view}/${currentParameters.dateRange.start.toString()}/${currentParameters.dateRange.end.toString()}`;
    if (currentParameters.campaign) {
      url = `${url}/${currentParameters.campaign}`;
    }
    data = await fetch(url, {
      headers: {
        Authorization: `Bearer ${$user.token}`
      }
    }).then(res => res.json());
    loading = false;
  }

  $: {
    if ($user.isAuthenticated && (!currentParameters || !(currentParameters.equals($parameters)))) {
      currentParameters = $parameters.clone();
      update();
    }
  }

  function showDetail({key, title, drillDown}) {
    if (typeof drillDown === "undefined" || drillDown === true) {
      detailPopup.show(key, title);
    }
  }
</script>

<div class="dashboard">
    {#if loading}
        <div class="loading">
            <Loading withOverlay={false}/>
            <h1>Please Wait</h1>
        </div>
    {:else}
        {#each data as tile}
            <ClickableTile on:click={() => showDetail(tile)}>
                <div class="tile">
                    <h1>{tile.title}</h1>
                    <h2>{tile.total.toLocaleString()}</h2>
                    <h3>
                        <div>
                            <small>Lending</small>
                            <span>&pound;{Math.round(tile.lending).toLocaleString()}</span>
                        </div>
                        {#if tile.key !== "leads"}
                            <div>
                                <small>Revenue</small>
                                <span>&pound;{Math.round(tile.revenue).toLocaleString()}</span>
                            </div>
                        {/if}
                    </h3>
                </div>
            </ClickableTile>
        {/each}
    {/if}
</div>

<Detail bind:this={detailPopup}/>

<style>
    .dashboard {
        position: absolute;
        top: 2rem;
        left: 2rem;
        bottom: 2rem;
        right: 2rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 2rem;
    }

    .loading {
        grid-row: 1 / end;
        grid-column: 1 / end;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .loading h1 {
        margin: 3rem 0 0 0;
    }

    .tile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .tile h1 {
        font-size: 2rem;
    }

    .tile h2 {
        font-size: 6rem;
    }

    .tile h3 {
        display: flex;
        margin: 0;
        text-align: center;
    }

    .tile h3 div {
        margin: 0.5rem;
    }

    .tile h3 small {
        display: block;
        font-size: 1rem;
    }

    .tile h3 span {
        font-size: 1.5rem;
    }
</style>
