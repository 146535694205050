<script>
  import {parameters, campaigns} from "../store";
  import {Dropdown} from "carbon-components-svelte";
  import DateRangePicker from "./DateRange/DateRangePicker.svelte";

  const viewOptions = [{id: "waterfall", text: "Waterfall"}, {id: "operational", text: "Operational"}, {
    id: "pipeline",
    text: "Pipeline"
  }];
  let viewIndex = viewOptions.findIndex(o => o.id === $parameters.view);
  $: $parameters.view = viewOptions[viewIndex].id;

  $: dateRangeDisabled = $parameters.view === "pipeline";

  function createProductOptions() {
    const result = [];
    if ($campaigns.firstCharge.length) {
      result.push({id: "firstCharge", text: "First Charge"});
    }
    if ($campaigns.secondCharge.length) {
      result.push({id: "secondCharge", text: "Second Charge"});
    }
    if ($campaigns.specialist.length) {
      result.push({id: "specialist", text: "Specialist Finance"});
    }
    return result;
  }

  const productOptions = createProductOptions();
  let productIndex = 0;
  $: $parameters.product = productOptions[productIndex].id;

  function createCampaignOptions(product) {
    const sameIntroducer = new Set($campaigns[product].map(c => c.introducer)).size === 1;
    const result = $campaigns[product].map(c => ({
      id: c.id,
      text: sameIntroducer ? c.name : `${c.introducer} - ${c.name}`
    }));
    result.sort((a, b) => a.text.localeCompare(b.text));
    return [{id: null, text: "All"}, ...result];
  }

  const campaignOptions = productOptions.map(p => createCampaignOptions(p.id));
  let campaignIndex = 0;
  $: $parameters.campaign = campaignOptions[productIndex][campaignIndex].id;

  $: productIndex, campaignIndex = 0;
</script>

<div class="parameters">
    {#if productOptions.length <= 1}
        <span/>
    {/if}
    <Dropdown titleText="View" bind:selectedIndex={viewIndex}
              items={viewOptions}/>
    <DateRangePicker titleText="Date Range" bind:value={$parameters.dateRange} disabled={dateRangeDisabled}/>
    {#if productOptions.length > 1}
        <Dropdown titleText="Product" bind:selectedIndex={productIndex} items={productOptions}/>
    {/if}
    <Dropdown titleText="Campaign" bind:selectedIndex={campaignIndex} items={campaignOptions[productIndex]}/>
</div>

<style>
    .parameters {
        display: grid;
        grid-template-columns: repeat(4, 250px);
        gap: 1rem;
    }
</style>
