import Auth0Client from "@auth0/auth0-spa-js";
import {user} from "./store";

const auth0 = new Auth0Client({
  domain: "id.click.tech",
  client_id: "mnH2pNs1o7hB1tZsEUFtHhttHfqKWMLs",
  audience: "https://decima.click.tech",
  cacheLocation: "localstorage",
  redirect_uri: location.origin
});

async function getToken() {
  try {
    return await (await auth0).getTokenSilently();
  } catch (e) {
    if (e.error !== "login_required" && e.error !== "consent_required") {
      throw e;
    }
    return null;
  }
}

async function handleRedirectCallback() {
  await (await auth0).handleRedirectCallback();
}

async function getUser() {
  return await (await auth0).getUser();
}

async function login() {
  return await (await auth0).loginWithRedirect();
}

async function logout() {
  (await auth0).logout({returnTo: location.origin});
}

async function checkSession() {
  // check for callback
  const queryParams = location.search.slice(1).split("&").map(kv => {
    const [key, value] = kv.split("=");
    return {key: decodeURIComponent(key), value: decodeURIComponent(value)};
  });
  const code = queryParams.find(({key}) => key === "code");
  if (code) {
    await handleRedirectCallback();
    // remove the code and state from the URL
    let uri = location.href.replace(location.search, "");
    const updatedQueryParams = queryParams.filter(kv => kv.key !== "code" && kv.key !== "state");
    if (updatedQueryParams.length) {
      uri = `${uri}?${updatedQueryParams.map(({
                                                key,
                                                value
                                              }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join("&")}`
    }
    history.replaceState(null, "", uri);
  }
  const token = await getToken();
  if (token === null) {
    await login();
  } else {
    user.set({isAuthenticated: true, token, profile: await getUser()});
  }
}

export default {
  checkSession,
  getToken,
  getUser,
  logout
}