<script>
  import router from "page";
  import {Button} from "carbon-components-svelte";
  import UserAvatar20 from "carbon-icons-svelte/lib/UserAvatar20";
  import auth from "./auth";
  import Dashboard from "./Dashboard.svelte";
  import Parameters from "./components/Parameters.svelte";

  // routing
  let page, params;
  router("/", () => (page = Dashboard));
  router.start();

  const headerActions = [
    {
      description: "Sign Out", icon: UserAvatar20, action: auth.logout
    }
  ];
</script>

<div class="app">
    <header>
        <div class="brand">
            <img src="/logo.png" alt="Believe" />
            <span>Introducer Portal</span>
        </div>
        <div class="actions">
            <Parameters />
            <span class="spacer"/>
            {#each headerActions as item}
                <Button kind="ghost" iconDescription={item.description} icon={item.icon} tooltipPosition="left"
                        tooltipAlignment="start" on:click={item.action}/>
            {/each}
        </div>
    </header>
    <main>
        <svelte:component this={page} params={params}/>
    </main>
</div>

<style>
    .app {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: grid;
        grid-template-rows: auto 1fr;
        grid-template-columns: 1fr;
    }

    header {
        grid-row: 1 / 2;
        grid-column: 1 / 2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2rem;
        border-bottom: 1px solid var(--cds-ui-04);
    }

    header .brand {
        display: flex;
        align-items: center;
        font-size: 1.25rem;
    }

    header .brand img {
        height: 3.5rem;
        margin-right: 1rem;
        background-color: white;
        padding: 0.5rem;
    }

    header .actions {
        display: flex;
        align-items: center;
    }

    header .spacer {
        display: block;
        width: 2rem;
    }

    main {
        grid-row: 2 / 3;
        grid-column: 1 / 2;
        background-color: var(--cds-ui-02);
        position: relative;
    }
</style>